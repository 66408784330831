<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ mortgage.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.mortgages.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in russian" placeholder="Please enter a name in russian" outlined v-model="mortgage.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in english" placeholder="Please enter a name in english" outlined v-model="mortgage.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in armenian" placeholder="Please enter a name in armenian" outlined v-model="mortgage.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <avatar-edit
                                    :current_url="mortgage.thumbnail_url"
                                    @input="handleSaveMortgage"
                                    v-model="mortgage.thumbnail_url"
                                ></avatar-edit>
                            </div>

                            <v-text-field label="Thumbnail URL" placeholder="Please enter a thumbnail url" outlined  v-model="mortgage.thumbnail_url" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="mortgage.bank_id"
                                :items="banks"
                                item-text="name"
                                item-value="id"
                                outlined
                                label="Select a bank"
                                required aria-required="true"
                            >
                            </v-select>
                            <v-text-field label="Bank ID:" v-model="mortgage.bank_id" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Min rate:" :rules="nameRules" outlined required v-model="mortgage.min_rate"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Max rate" outlined v-model="mortgage.max_rate" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created at" v-model="mortgage.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveMortgage">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Mortgage from "../../../models/Mortgage";
import Bank from '../../../models/Bank';
import AvatarEdit from "../../../components/AvatarEdit";


import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

export default {
    name: "mortgages-show",
    components: {AdminDashboardLayout,AvatarEdit},
    data: function () {
        return {
            mortgage: {},
            formValid: true,
            selected: '',
            banks: [],
            nameRules: [v => !!v || 'Minimum rate is required'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    async mounted() {
        const mortgageId = this.$route.params.id
        if(mortgageId) {
            this.mortgage = await Mortgage.find(mortgageId);
        }
        this.banks = await Bank.get();
    },
    methods: {
        async handleSaveMortgage() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const mortgage = new Mortgage(this.mortgage);
                this.mortgage = await mortgage.save()
                await this.$router.push({name: 'admin.mortgages.show', params: {id: this.mortgage.id}}).catch(()=>{});
                this.snackbar = true
            }
        }
    },
}
</script>

<style scoped>

</style>
